import { ROUTE_NAMES, TRoutes } from './interfaces'

export const routes: TRoutes = {
  [ROUTE_NAMES.HOME]: `/`,
  [ROUTE_NAMES.PROFILE_CREATE]: `/profile/create/:subscriptionId`,
  [ROUTE_NAMES.START_PAGE]: `/start/:userChildId`,
  [ROUTE_NAMES.LESSONS_PAGE]: `/lessons/:userChildId`,
  [ROUTE_NAMES.INTRODUCTION_LESSON_PAGE]: `/lessons/:userChildId/introduction`,
  [ROUTE_NAMES.NORMAL_LESSON_PAGE]: `/lessons/:userChildId/start`,
  [ROUTE_NAMES.LIBRARY_PAGE]: `/library/:userChildId`,
  [ROUTE_NAMES.DOWNLOADS_PAGE]: `/library/:userChildId/downloads`,
  [ROUTE_NAMES.DICTIONARY_PAGE]: `/library/:userChildId/dictionary`,
  [ROUTE_NAMES.RECITATIONS_PAGE]: `/library/:userChildId/recitations`,
  [ROUTE_NAMES.LIBRARY_MEDIA_PAGE]: `/library/:userChildId/media`,
  [ROUTE_NAMES.PERSONAL_DEVELOPMENT_PAGE]: `/library/:userChildId/personal_development`,
  [ROUTE_NAMES.FRIEND]: `/friends/:userChildId/details`,
  [ROUTE_NAMES.FRIENDS]: `/friends/:userChildId`,
  [ROUTE_NAMES.MESSAGES]: `/messages/:userChildId`,
  [ROUTE_NAMES.USER_CHILD_PROFILE]: `profile/:userChildId`,
  [ROUTE_NAMES.PARENT_PROFILES]: `parent/profiles`,
  [ROUTE_NAMES.PARENT_PROFILES_CHILD]: `parent/profiles/child`,
  [ROUTE_NAMES.PARENT_PROFILES_CHILD_DETAILS]: `parent/profiles/child/details`,
  [ROUTE_NAMES.PARENT_PROFILES_CHILD_UNLOCK]: `parent/profiles/child/unlock`,
  [ROUTE_NAMES.PARENT_PROFILES_CHILD_FINAL_TEST]: `parent/profiles/child/final_test`,
  [ROUTE_NAMES.PARENT_PROFILES_CHILD_PROGRESSION]: `parent/profiles/child/progression`,
  [ROUTE_NAMES.PARENT_PROFILES_CHILD_SEND_MESSAGE]: `parent/profiles/child/send_message`,
  [ROUTE_NAMES.PARENT_LESSONS]: `parent/lessons`,
  [ROUTE_NAMES.PARENT_ACCOUNT]: `parent/account`,
  [ROUTE_NAMES.FINAL_TEST]: `/test/:userChildId`,
  [ROUTE_NAMES.FINAL_MESSAGE]: `/final_message/:userChildId`,
  [ROUTE_NAMES.CERTIFICATE]: `/certificate/:userChildId`,
}

import React, { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { SuspenseLoading } from '../../Components'
import { Footer } from '../../Components/Footer'
import PageNotFound from '../../Pages/PageNotFound'
import { CurrentUserProvider } from '../../Providers/CurrentUserProvider'
import { ROUTE_NAMES } from './interfaces'
import { routes } from './routes'
import Styles from './styles.module.scss'

const Home = lazy(() => import(/* webpackChunkName: "DashboardPage" */ '../../Pages/Home'))
const ProfileCreate = lazy(() => import(/* webpackChunkName: "ProfileCreatePage" */ '../../Pages/Profile/Create'))
const StartPage = lazy(() => import(/* webpackChunkName: "StartPage" */ '../../Pages/Start'))
const LessonsPage = lazy(() => import(/* webpackChunkName: "LessonsPage" */ '../../Pages/Lessons'))
const IntroductionLessonPage = lazy(
  () => import(/* webpackChunkName: "IntroductionLessonPage" */ '../../Pages/IntroductionLesson')
)
const NormalLessonPage = lazy(() => import(/* webpackChunkName: "NormalLessonPage" */ '../../Pages/NormalLesson'))
const LibraryPage = lazy(() => import(/* webpackChunkName: "NormalLessonPage" */ '../../Pages/Library'))
const DownloadsPage = lazy(() => import(/* webpackChunkName: "DownloadsPage" */ '../../Pages/Downloads'))
const DictionaryPage = lazy(() => import(/* webpackChunkName: "DictionaryPage" */ '../../Pages/Dictionary'))
const RecitationsPage = lazy(() => import(/* webpackChunkName: "RecitationsPage" */ '../../Pages/Recitations'))
const LibraryMediaPage = lazy(() => import(/* webpackChunkName: "LibraryMediaPage" */ '../../Pages/LibraryMedia'))
const PersonalDevelopment = lazy(
  () => import(/* webpackChunkName: "PersonalDevelopment" */ '../../Pages/PersonalDevelopment')
)
const UserChildProfilePage = lazy(
  () => import(/* webpackChunkName: "UserChildProfilePage" */ '../../Pages/Profile/Edit')
)
const FriendsPage = lazy(() => import(/* webpackChunkName: "FriendsPage" */ '../../Pages/Friends'))
const ParentProfilesPage = lazy(
  () => import(/* webpackChunkName: "ParentProfilesPage" */ '../../Pages/Parent/Profiles')
)
const ParentLessonsPage = lazy(() => import(/* webpackChunkName: "ParentLessonsPage" */ '../../Pages/Parent/Lessons'))
const ParentAccountPage = lazy(() => import(/* webpackChunkName: "ParentAccountPage" */ '../../Pages/Parent/Account'))
const ParentProfilesChildPage = lazy(
  () => import(/* webpackChunkName: "ParentProfilesChildPage" */ '../../Pages/Parent/Profiles/Profile')
)
const ParentProfilesChildDetailsPage = lazy(
  () => import(/* webpackChunkName: "ParentProfilesChildDetailsPage" */ '../../Pages/Parent/Profiles/Profile/Details')
)
const ParentProfilesChildUnlockPage = lazy(
  () => import(/* webpackChunkName: "ParentProfilesChildUnlockPage" */ '../../Pages/Parent/Profiles/Profile/Unlock')
)
const ParentProfilesChildFinalTestPage = lazy(
  () =>
    import(/* webpackChunkName: "ParentProfilesChildFinalTestPage" */ '../../Pages/Parent/Profiles/Profile/FinalTest')
)
const ParentProfilesChildProgressionPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ParentProfilesChildProgressionPage" */ '../../Pages/Parent/Profiles/Profile/Progression'
    )
)
const ParentProfilesChildSendMessagePage = lazy(
  () =>
    import(
      /* webpackChunkName: "ParentProfilesChildSendMessagePage" */ '../../Pages/Parent/Profiles/Profile/SendMessage'
    )
)
const FinalTestPage = lazy(() => import(/* webpackChunkName: "FinalTestPage" */ '../../Pages/FinalTest'))
const FinalMessagePage = lazy(() => import(/* webpackChunkName: "FinalMessagePage" */ '../../Pages/FinalMessage'))
const CertificatePage = lazy(() => import(/* webpackChunkName: "CertificatePage" */ '../../Pages/Certificate'))
const MessagesPage = lazy(() => import(/* webpackChunkName: "MessagesPage" */ '../../Pages/Messages'))
const FriendPage = lazy(() => import(/* webpackChunkName: "FriendPage" */ '../../Pages/Friend'))

const Router: React.FC = (): JSX.Element => {
  const renderRoutes = (): JSX.Element => {
    return (
      <React.Fragment>
        <Route path={routes[ROUTE_NAMES.HOME]} element={<Home />} />
        <Route path={routes[ROUTE_NAMES.PROFILE_CREATE]} element={<ProfileCreate />} />
        <Route path={routes[ROUTE_NAMES.START_PAGE]} element={<StartPage />} />
        <Route path={routes[ROUTE_NAMES.LESSONS_PAGE]} element={<LessonsPage />} />
        <Route path={routes[ROUTE_NAMES.INTRODUCTION_LESSON_PAGE]} element={<IntroductionLessonPage />} />
        <Route path={routes[ROUTE_NAMES.NORMAL_LESSON_PAGE]} element={<NormalLessonPage />} />
        <Route path={routes[ROUTE_NAMES.LIBRARY_PAGE]} element={<LibraryPage />} />
        <Route path={routes[ROUTE_NAMES.DOWNLOADS_PAGE]} element={<DownloadsPage />} />
        <Route path={routes[ROUTE_NAMES.DICTIONARY_PAGE]} element={<DictionaryPage />} />
        <Route path={routes[ROUTE_NAMES.RECITATIONS_PAGE]} element={<RecitationsPage />} />
        <Route path={routes[ROUTE_NAMES.LIBRARY_MEDIA_PAGE]} element={<LibraryMediaPage />} />
        <Route path={routes[ROUTE_NAMES.USER_CHILD_PROFILE]} element={<UserChildProfilePage />} />
        <Route path={routes[ROUTE_NAMES.PERSONAL_DEVELOPMENT_PAGE]} element={<PersonalDevelopment />} />
        <Route path={routes[ROUTE_NAMES.FRIENDS]} element={<FriendsPage />} />
        <Route path={routes[ROUTE_NAMES.FRIEND]} element={<FriendPage />} />
        <Route path={routes[ROUTE_NAMES.PARENT_PROFILES]} element={<ParentProfilesPage />} />
        <Route path={routes[ROUTE_NAMES.PARENT_LESSONS]} element={<ParentLessonsPage />} />
        <Route path={routes[ROUTE_NAMES.PARENT_ACCOUNT]} element={<ParentAccountPage />} />
        <Route path={routes[ROUTE_NAMES.PARENT_PROFILES_CHILD]} element={<ParentProfilesChildPage />} />
        <Route path={routes[ROUTE_NAMES.PARENT_PROFILES_CHILD_DETAILS]} element={<ParentProfilesChildDetailsPage />} />
        <Route path={routes[ROUTE_NAMES.MESSAGES]} element={<MessagesPage />} />
        <Route path={routes[ROUTE_NAMES.PARENT_PROFILES_CHILD_UNLOCK]} element={<ParentProfilesChildUnlockPage />} />
        <Route
          path={routes[ROUTE_NAMES.PARENT_PROFILES_CHILD_PROGRESSION]}
          element={<ParentProfilesChildProgressionPage />}
        />
        <Route
          path={routes[ROUTE_NAMES.PARENT_PROFILES_CHILD_SEND_MESSAGE]}
          element={<ParentProfilesChildSendMessagePage />}
        />
        <Route
          path={routes[ROUTE_NAMES.PARENT_PROFILES_CHILD_FINAL_TEST]}
          element={<ParentProfilesChildFinalTestPage />}
        />
        <Route path={routes[ROUTE_NAMES.FINAL_TEST]} element={<FinalTestPage />} />
        <Route path={routes[ROUTE_NAMES.FINAL_MESSAGE]} element={<FinalMessagePage />} />
        <Route path={routes[ROUTE_NAMES.CERTIFICATE]} element={<CertificatePage />} />

        {/* Keep as the last path! */}
        <Route path="*" element={<PageNotFound />} />
      </React.Fragment>
    )
  }

  return (
    <TransitionGroup className={Styles.appRoutesTransitionGroup}>
      <CSSTransition key={location.pathname} timeout={{ enter: 250, exit: 0 }} classNames="fade">
        <Routes>{renderRoutes()}</Routes>
      </CSSTransition>
    </TransitionGroup>
  )
}

export const AuthorizedLayout: React.FC = (): JSX.Element => {
  return (
    <div className={Styles.authorizedLayout}>
      <CurrentUserProvider>
        <Suspense fallback={<SuspenseLoading />}>
          <Router />
          <Footer />
        </Suspense>
      </CurrentUserProvider>
    </div>
  )
}

import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, InMemoryCacheConfig } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import ApolloLinkNetworkStatus from 'react-apollo-network-status/dist/src/ApolloLinkNetworkStatus'

import { generateApolloClientHeaders } from './headersHelper'

export const sharedInMemoryCacheOptions: InMemoryCacheConfig = {
  typePolicies: {},
}

const httpLink = createHttpLink({
  uri: 'https://online-studies-hasura.yathrib.daralilm.nl/v1/graphql',
})

const authorizedLink = (token: string | null): ReturnType<typeof setContext> => {
  return setContext((_request, previousContext) => {
    return {
      headers: {
        ...previousContext.headers,
        ...generateApolloClientHeaders({ token, withAuthorization: true }),
      },
    }
  })
}

const errorLink = (logoutFn: () => Promise<void> | void): ApolloLink => {
  return onError(({ networkError }) => {
    if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) logoutFn()
  })
}

const generateClient = (
  receivedLink: ReturnType<typeof setContext>,
  link?: ApolloLinkNetworkStatus
): InstanceType<typeof ApolloClient> => {
  return new ApolloClient({
    cache: new InMemoryCache(sharedInMemoryCacheOptions),
    link: link ? link.concat(receivedLink.concat(httpLink)) : receivedLink.concat(httpLink),
    connectToDevTools: true,
  })
}

export const authorizedClient = (
  token: string | null,
  link: ApolloLinkNetworkStatus,
  logoutFn: () => Promise<void> | void
): ApolloClient<unknown> => generateClient(authorizedLink(token).concat(errorLink(logoutFn)), link)

const unauthorizedLink = (): ReturnType<typeof setContext> => {
  return setContext((_request, previousContext) => {
    return {
      headers: {
        ...previousContext.headers,
        ...generateApolloClientHeaders({ withAuthorization: false }),
      },
    }
  })
}

export const unauthorizedClient = (): ApolloClient<unknown> => generateClient(unauthorizedLink())
